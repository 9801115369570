import React from 'react';
import styles from '../ContactUs/contactus.module.css';

const ContactCard = ({ img, post, name, phn, email }) => {
  return (
    <div className={styles.card}>
      <img src={img} alt={`${name}'s profile`} />
      <div className={styles.imgbg}></div>
      <div className={styles.info}>
        <div className={styles.text}>
          <p>{name}</p>
          <p>{post}</p>
          <p className={styles.hidden}>{phn}</p>
          <p className={styles.hidden}>{email}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
