import React, { useState,useEffect } from 'react'
import styles from './lectures.module.css'
import bg from './assets/image1.jpeg'
import MapClick from "./m-map";
import WorldMap from "./map";
import OurReach from './ourreach.jsx';
import leccard from './assets/cardlec.png';
import leccard_new from './assets/cardlec4.png';
import lec1 from './assets/lecim1.png'
// import leccard_new from './assets/cardlec3.png';
import AkashAmbani from './assets/AkashAmbani.jpg'
import lectures from './assets/lectures.png'
import SSomnath from './assets/l11.jpg'
import APJKalam from './assets/I1_new.png'
import I3_new from './assets/I3_new.jpg'
import I9_new from './assets/I9.jpg'
import I6_new from './assets/I6_new.jpg'
import DalaiLama_new from './assets/DalaiLama_new.jpg'
import Zak from './assets/ZaheerKhan.png'
import Vishy from './assets/VishyAnand.jpg'



// import ContactCard from '../../components/Contact/contact.jsx'
// import tiger from './assets/test_tiger.jpeg'


const Lectures = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    // console.log(window.innerWidth)
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    const bgHaiJi = {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.6)), url(${bg})`,
        backgroundColor: "lightblue",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        // backgroundColor:'#e3e3ed',
        width: "100vw",
        position: "absolute",
        top: "0",
        zIndex: "-1",
        backgroundAttachment: "fixed",
      };

      const [visibleParaIndex, setVisibleParaIndex] = useState(null);

      // let val = paraVisible;
      
      const display = (index) => {
        // if(val===false){
        // setParaVisible(true);
        // }
        // else{
        //   setParaVisible(false);
        // }
        setVisibleParaIndex(index === visibleParaIndex ? null : index); // Toggle visibility

      };

  return (
    <>
    <div className={styles.lectures} style={bgHaiJi}>
    <div>
      <div className={styles.heading}>
      <img src={lectures}></img>
      </div>
      {/* <h1 className={styles.heading}>LECTURES</h1> */}
        <main className={styles.cardsBox}>
          <div className={styles.card}>
            <img src={leccard_new} className={styles.cardlayout}></img>
            {/* <button className={styles.btn} onClick={() => display(0)}>more info</button> */}
            <p className={styles.para} style={{ display: visibleParaIndex === 0 ? 'block' : 'none' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et explicabo sed corrupti accusamus! Autem pariatur consequatur ratione dolore maiores nostrum accusamus.</p>
            <img className={styles.image} src={APJKalam} alt="" />
            <div className={styles.text_lect}>
            <h4 className={styles.nameInfo}>Dr APJ Abdul Kalam</h4>
            <p className={styles.parainfo}>Former President of India, also know as Missile man of India</p>
            </div>
            {/* <div className={styles.polygonL}>
              <p></p>
            </div> */}


            </div>

          <div className={styles.card}>
          <img src={leccard_new} className={styles.cardlayout}></img>
            {/* <button className={styles.btn} onClick={() => display(1)}>more info</button> */}
            {/* <p className={styles.para} style={{ display: visibleParaIndex === 1 ? 'block' : 'none' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et explicabo sed corrupti accusamus! Autem pariatur consequatur ratione dolore maiores nostrum accusamus.</p> */}
            <img className={styles.image} src={I6_new} alt="" />
            <div className={styles.text_lect}>
            <div className={styles.nameInfo}>
            <h4 >Tarja Halonen</h4>
            </div>
            <p className={styles.parainfo}>First female President of Finland and currently a part of Council of Women World Leaders</p>
            </div>
            {/* <div className={styles.polygonL}>
              <p></p>
            </div>

            <div className={styles.polygonR}>
              <p></p>
            </div> */}
            
          </div>
          <div className={styles.card}>
          <img src={leccard_new} className={styles.cardlayout}></img>
            {/* <button className={styles.btn}  onClick={() => display(2)}>more info</button> */}
            {/* <p className={styles.para} style={{ display: visibleParaIndex === 2 ? 'block' : 'none' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et explicabo sed corrupti accusamus! Autem pariatur consequatur ratione dolore maiores nostrum accusamus.</p> */}
            <img className={styles.image} src={DalaiLama_new} alt="" />
            <div className={styles.text_lect}>
            <h4 className={styles.nameInfo}>Dalai Lama</h4>
            <p className={styles.parainfo}>His holiness 14th Dalai lama, Nobel Peace Prize Winner</p>
            </div>
            {/* <div className={styles.polygonL}>
              <p></p>
            </div>

            <div className={styles.polygonR}>
              <p></p>
            </div> */}
            
          </div>
          <div className={styles.card}>
          <img src={leccard_new} className={styles.cardlayout}></img>
            {/* <button className={styles.btn}  onClick={() => display(3)}>more info</button> */}
            <p className={styles.para} style={{ display: visibleParaIndex === 3 ? 'block' : 'none' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et explicabo sed corrupti accusamus! Autem pariatur consequatur ratione dolore maiores nostrum accusamus.</p>
            <img className={styles.image} src={I9_new} alt="" />
            <div className={styles.text_lect}>
            <h4 className={styles.nameInfo}>Malcolm Turnbull</h4>
            <p className={styles.parainfo}>26th Prime Minister of Australia, was part of Lecture Series of Techfest 2020-21</p>
            </div>
            
          </div>
          <div className={styles.card}>
          <img src={leccard_new} className={styles.cardlayout}></img>
            {/* <button className={styles.btn}  onClick={() => display(4)}>more info</button> */}
            <p className={styles.para} style={{ display: visibleParaIndex === 4 ? 'block' : 'none' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et explicabo sed corrupti accusamus! Autem pariatur consequatur ratione dolore maiores nostrum accusamus.</p>
            <img className={styles.image} src={I3_new} alt="" />
            <div className={styles.text_lect}>
            <h4 className={styles.nameInfo}>N. R. Narayana Murthy</h4>
            <p className={styles.parainfo}>Co-founder and Ex-Chairman of Infosys, also known as Father of India's I.T. sector</p>
            </div>
          </div>
          <div className={styles.card}>
          <img src={leccard_new} className={styles.cardlayout}></img>
            {/* <button className={styles.btn}  onClick={() => display(5)}>more info</button> */}
            <p className={styles.para} style={{ display: visibleParaIndex === 5 ? 'block' : 'none' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et explicabo sed corrupti accusamus! Autem pariatur consequatur ratione dolore maiores nostrum accusamus.</p>
            <img className={styles.image} src={AkashAmbani} alt="" />
            <div className={styles.text_lect}>
            <h4 className={styles.nameInfo}>Akash Ambani</h4>
            <p className={styles.parainfo}>Chairman of Reliance Jio Infocomm Limited</p>
            </div>
            
          </div>
          <div className={styles.card}>
          <img src={leccard_new} className={styles.cardlayout}></img>
            {/* <button className={styles.btn}  onClick={() => display(6)}>more info</button> */}
            <p className={styles.para} style={{ display: visibleParaIndex === 6 ? 'block' : 'none' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et explicabo sed corrupti accusamus! Autem pariatur consequatur ratione dolore maiores nostrum accusamus.</p>
            <img className={styles.image} src={SSomnath} alt="" />
            <div className={styles.text_lect}>
            <h4 className={styles.nameInfo}>Shri S.Somnath</h4>
            <p className={styles.parainfo}>Chairman of ISRO</p>
            </div>
            
          </div>
          <div className={styles.card}>
          <img src={leccard_new} className={styles.cardlayout}></img>
            {/* <button className={styles.btn} onClick={() => display(7)}>more info</button> */}
            <p className={styles.para} style={{ display: visibleParaIndex === 7 ? 'block' : 'none' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et explicabo sed corrupti accusamus! Autem pariatur consequatur ratione dolore maiores nostrum accusamus.</p>
            <img className={styles.image} src={Vishy} alt="" />
            <div className={styles.text_lect}>
            <h4 className={styles.nameInfo}>Viswanathan Anand</h4>
            <p className={styles.parainfo}>Indian chess grandmaster and a former five-time World Chess Champion</p>
            </div>
            
          </div>
        </main>
        <div>{isMobile ? <MapClick /> : <WorldMap />}</div>
        <OurReach/>
        {/* <div className="tray">

            <div>
              <ContactCard
                image={tiger}
                headText="Contact Us"
                emailText="contact@example.com"
                phoneNumberText="123-456-7890"
              />
            </div>
           
            <div>
              <ContactCard
                image=""
                headText="Contact Us"
                emailText="contact@example.com"
                phoneNumberText="123-456-7890"
              />
            </div>
          </div> */}
          <div className={styles.Contactbox}>
            <div className="Contactbox1">
              Divyansh Ranjan<br></br>
              ranjan@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${9619678850}`)}>
                {8318806303}
              </div>
            </div>
            
          </div>
    </div>

</div>
</>
  )
}

export default Lectures
