import React from 'react';
import styles from './home.module.css';

const Card = ({image,path,text,description}) => {
  return (
    <div className={styles.card}>
        <div className={styles.front}>
            <div className={styles.cardicon}>
                <img src={image} alt={`${text} "Icon"`}/>
            </div>
            <div className={styles.textoverlay}>
                <h3 className={styles.cardtitle}>{text}</h3>
                <p className={styles.carddescription}>
                    {description}
                </p>
                <a href={path} className={styles.cardbutton}>Explore</a>
            </div>
            
        </div>
        <div className={styles.back}>
            <div className={styles.cardicon}>
                <img src={image} alt={`${text} "Icon"`}/>
            </div>
            <h2 className={styles.cardtitle}>{text}</h2>
        </div>
    </div>
  )
}

export default Card
