// ConfirmModal.js
import React from 'react';
import './ConfirmModal.css';

const ConfirmModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 style={{color:'white',fontFamily:'Bruno Ace'}}>Confirm Sign Out</h2>
        <p style={{color:'white',fontFamily:'Bruno Ace'}}>Are you sure you want to sign out?</p>
        <div className="modal-buttons">
          <button className="confirm-btn" onClick={onConfirm}>Yes</button>
          <button className="cancel-btn" onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
