import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithRedirect } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDQR1wk6lsTqOxxx_KSJPraWdZGHP2tOR8",
    authDomain: "techfest2024-37a66.firebaseapp.com",
    projectId: "techfest2024-37a66",
    storageBucket: "techfest2024-37a66.appspot.com",
    messagingSenderId: "125841929055",
    appId: "1:125841929055:web:591f42a0c06e46c9277cea",
    measurementId: "G-D7GTK2Z5HP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app)

const provider = new GoogleAuthProvider()

export const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
    .then((result) => {
        console.log(result)
    })
    .catch((error) => {
        console.log(error)
    })
}

export const signInwithoutPopup = () => {
    signInWithRedirect(auth, provider)
    .then((result) => {
        console.log(result)
        // window.open(result.url, '_blank')
    })
    .catch((error) => {
        console.log(error)
    })
}