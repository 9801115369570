// import React, { useState, useEffect, useRef } from 'react';
// import styles from './carousel.module.css';

// // Import images directly
// import image1 from './assets/c1.png';
// import image2 from './assets/c2.png';
// import image3 from './assets/c3.png';

// const images = [image1, image2, image3];

// const Carousel = () => {
//     const [currentIndex, setCurrentIndex] = useState(0);
//     const [previousIndex, setPreviousIndex] = useState(images.length - 1);
//     const timeoutRef = useRef(null);

//     const delay = 3000;

//     const resetTimeout = () => {
//         if (timeoutRef.current) {
//             clearTimeout(timeoutRef.current);
//         }
//     };

//     useEffect(() => {
//         resetTimeout();
//         timeoutRef.current = setTimeout(
//             () => {
//                 setPreviousIndex(currentIndex);
//                 setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
//             },
//             delay
//         );

//         return () => {
//             resetTimeout();
//         };
//     }, [currentIndex]);

//     return (
//         <div className={styles.carouselContainer}>
//             {images.map((image, index) => (
//                 <div
//                     key={index}
//                     className={`${styles.imageContainer} ${index === currentIndex ? styles.current : ''} ${index === previousIndex ? styles.previous : ''}`}
//                     style={{ backgroundImage: `url(${image})` }}
//                 />
//             ))}
//         </div>
//     );
// };

// export default Carousel;

// import React, { useState, useEffect, useRef } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import styles from './carousel.module.css';

// // Import images directly
// import image1 from './assets/c1.png';
// import image2 from './assets/c2.png';
// import image3 from './assets/c3.png';

// gsap.registerPlugin(ScrollTrigger);

// const images = [image1, image2, image3];

// const Carousel = () => {
//     const [currentIndex, setCurrentIndex] = useState(0);
//     const [previousIndex, setPreviousIndex] = useState(images.length - 1);
//     const timeoutRef = useRef(null);
//     const carouselRef = useRef(null);

//     const delay = 3000;

//     const resetTimeout = () => {
//         if (timeoutRef.current) {
//             clearTimeout(timeoutRef.current);
//         }
//     };

//     useEffect(() => {
//         resetTimeout();
//         timeoutRef.current = setTimeout(
//             () => {
//                 setPreviousIndex(currentIndex);
//                 setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
//             },
//             delay
//         );

//         return () => {
//             resetTimeout();
//         };
//     }, [currentIndex]);

//     useEffect(() => {
//         gsap.to(carouselRef.current, {
//             scrollTrigger: {
//                 trigger: carouselRef.current,
//                 start: "top top",
//                 end: "+=500",
//                 scrub: true,
//             },
//             width: "300px",
//             height: "220px",
//             left:"40vw",
//             top:'100vh',
//             ease: "none",
//             onUpdate: function() {
//                 if (carouselRef.current.clientWidth <= 100) {
//                     gsap.set(carouselRef.current, {
//                         width: "300px",
//                         height: "220px",
//                         left:"40vw",
//                         top:'100vh',
//                         clearProps: "all"
//                     });
//                 }
//             }
//         });

//     }, []);

//     return (
//         <div className={styles.carouselContainer} ref={carouselRef}>
//             {images.map((image, index) => (
//                 <div
//                     key={index}
//                     className={`${styles.imageContainer} ${index === currentIndex ? styles.current : ''} ${index === previousIndex ? styles.previous : ''}`}
//                     style={{ backgroundImage: `url(${image})` }}
//                 />
//             ))}
//         </div>
//     );
// };

// export default Carousel;

import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styles from './carousel.module.css';

// Import images directly
import image1 from './assets/c1.png';
import image2 from './assets/c2.png';
import image3 from './assets/c3.png';

gsap.registerPlugin(ScrollTrigger);

const images = [image1, image2, image3];

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState(images.length - 1);
    const timeoutRef = useRef(null);
    const carouselRef = useRef(null);
    const infiniteCarouselRef = useRef(null);
    const [isInfinite, setIsInfinite] = useState(false);

    const delay = 3000;

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () => {
                setPreviousIndex(currentIndex);
                setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
            },
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [currentIndex]);

    useEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: carouselRef.current,
                    start: "top top",
                    end: "+=500",
                    scrub: true,
                    onLeave: () => setIsInfinite(true),
                    onEnterBack: () => setIsInfinite(false),
                }
            });

            tl.to(carouselRef.current, {
                width: "300px",
                height: "220px",
                left: "40vw",
                top: "90vh",
                ease: "none",
            });

            gsap.to(infiniteCarouselRef.current, {
                scrollTrigger: {
                    trigger: infiniteCarouselRef.current,
                    start: "top bottom",
                    end: "+=500",
                    scrub: true,
                    pin: true,
                },
                top: "50vh",
                ease: "none",
            });
        });

        return () => {
            ctx.revert();
        };
    }, []);

    useEffect(() => {
        if (isInfinite) {
            const scrollContainer = infiniteCarouselRef.current;
            const scrollWidth = scrollContainer.scrollWidth / 2;
            const scrollSpeed=3;

            const scroll = () => {
                scrollContainer.scrollLeft += scrollSpeed;
                if (scrollContainer.scrollLeft >= scrollWidth) {
                    scrollContainer.scrollLeft = 0;
                }
                requestAnimationFrame(scroll);
            };

            requestAnimationFrame(scroll);
        }
    }, [isInfinite]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.carouselContainer} ref={carouselRef}>
                {!isInfinite && images.map((image, index) => (
                    <div
                        key={index}
                        className={`${styles.imageContainer} ${index === currentIndex ? styles.current : ''} ${index === previousIndex ? styles.previous : ''}`}
                        style={{ backgroundImage: `url(${image})` }}
                    />
                ))}
            </div>
            {isInfinite && (
                <div className={styles.infiniteCarousel} ref={infiniteCarouselRef}>
                    {images.concat(images).map((image, index) => (
                        <div
                            key={index}
                            className={styles.infiniteImage}
                            style={{ backgroundImage: `url(${image})` }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default Carousel;











