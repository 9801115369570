import React from 'react';
import Counter from '../../components/NewReach/counter';
import mylogo from '../Lectures/assets/grouped_circle.png'
import '../Lectures/ourreach.css';
import downrot from '../Lectures/assets/downrot.png'
import upprot from '../Lectures/assets/upprot.png'
import ourreach from '../Lectures/assets/ourreach.png'



function OurReach() {
  return (
    <>
      <div className='ourReach'>
        <img src={ourreach}></img>
        {/* <h1 style={{marginTop:'40px',marginBottom:'40px'}}>OUR REACH</h1> */}
      </div>
      <div className='loader'>
        <div className="buffer">
          <img src={mylogo} alt="buffering" id='myImg'/>
          <img src={downrot} alt="downrot" id="down"/>
          <img src={upprot} alt="downrot" id="up"/>
          <div className='Overlay'>
            {/* <Counter targetValue={65} duration={2000} scrollVal={100} plus='L+'/> */}
            <p>65L+</p>
          </div>
          <div className={"bottomhead"}>
            <h2>Prize Money</h2>
          </div>
        </div>
        <div className="buffer">
          <img src={mylogo} alt="buffering" id='myImg'/>
          <img src={downrot} alt="downrot" id="down"/>
          <img src={upprot} alt="downrot" id="up"/>
          <div className='Overlay'>
            {/* <Counter targetValue={25} duration={2000} scrollVal={200} plus='+'/> */}
            <p>25+</p>
          </div>
          <div className='bottomhead'>
            <h2 style={{justifyContent:'center',alignItems:'center',display:'flex',textAlign:'center'}}>Competitions</h2>
          </div>
        </div>
        <div className="buffer">
          <img src={mylogo} alt="buffering" id='myImg'/>
          <img src={downrot} alt="downrot" id="down"/>
          <img src={upprot} alt="downrot" id="up"/>
          <div className='Overlay'>
            {/* <Counter targetValue={35} duration={2000} scrollVal={100} plus='K+'/> */}
            <p>35K+</p>
          </div>
          <div className='bottomhead'>
            <h2>Participants</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurReach;
