import React from 'react'
import style from './ComingSoon.module.css'

export default function ComingSoon() {
  return (
    <div className={style.card}>
        <h1 className={style.head}></h1>
    </div>
  )
}
