import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { Frame3 } from "./compi_animation";
import Technorion from "./technorion";
import OurReach from "./ourreach";
// import Carousel from "../../components/Carousel/carousel";
import "./compi_animation.css";
// import '../Lectures/lecture.css';

const Compi = () => {
  const [data, setData] = useState([]);
  const { user } = UserAuth();
  
  const google_id = user?.uid;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://techfest.org/api/compi_card/", {
          headers: {
            "X-Google-UID": google_id,
          },
        });
        console.log(axios.defaults.headers.common);
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const card = () => {
    return data.map((data) => (
      <div key={data.id}>
        <h2>{data.tag}</h2>
        <p>{data.name}</p>
        <p>{data.prize}</p>
        <p>{data.desc}</p>
        <p>
          <img src={data.img} alt='imagnot' />
        </p>
        {data.is_registered ? (
          <p>Registered</p>
        ) : (
          <p>
            <Link to={`${data.name}/register`}>Register</Link>
          </p>
        )}
        <p>
          <Link to={data.name}>Explore</Link>
        </p>
      </div>
    ));
  };

  return (
    <>
    <div className="competitions" style={{position:'absolute',top:'0'}}>
      <div className="compi_bg"></div>
      {/* <Frame3 /> */}
      <Technorion />
      <OurReach />
      <br /><br /><br /><br />
      <div className='Contactbox'>
            <div className="Contactbox1">
              Rishabh Bhardwaj<br></br>
              rishabh@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${9619678850}`)}>
                {9798273763}
              </div>
            </div>
            <div className="Contactbox1">
            Mrutyunjaya Sahu<br></br>
              mrutyunjaya@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${7317520804}`)}>
                {9692376527}
              </div>
            </div>
            <div className="Contactbox1">
              Basil Faruqui<br></br>
              basil@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${7317520804}`)}>
                {9399952997}
              </div>
            </div>
          </div>
      {/* {card()} */}
       {/* <div className="checkThis">
        <h1 style={{ marginTop: "0px", marginBottom: "40px" }}>
          CHECK THIS OUT
        </h1>
        <div style={{ height: "390px" }}>
          <h2 style={{ marginBottom: "0px" }}>Exhibition</h2>
         
          <div className="difPage">
            <div className="check_rect1"></div>
            <div className="check_rect2">
              <Link to="/exhibitions">Explore</Link>
            </div>
          </div>
          <br /><br /><br /><br /><br /><br /><br /><br />
        </div>
        <div style={{marginBottom: '150px'}}>
          <h2 className="gap" >Lectures</h2>
        
          <div className="difPage">
            <div className="check_rect1"></div>
            <div className="check_rect2">
              <Link to="/lectures">Explore</Link>
            </div>
          </div>
        </div>
      </div> */}
      
    </div>
    </>
  );
};

export default Compi;
