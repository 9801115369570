import React, { useEffect, useState } from 'react';
import { gsap, ScrollTrigger } from 'gsap/all';
import styles from './home.module.css';
import bg from './assets/image.png';
import logo from '../../assets/bg/akshit.jpg'; // Use the same logo for simplicity, or add more as needed
import tflogo from './assets/tflogo.png'; // Add the Techfest logo image
import Carousel from '../../components/Carousel/carousel';
import robot from './assets/robot.png';
import workshop from './assets/workshop.png';
import workhead from './assets/WORKSHOP_HEADER.svg';
import compihead from './assets/COMPETITIONS_HEADER.svg';
import pastlectures from './assets/PAST_LECTURES_HEADER.svg';
import titlehead from './assets/titlehead.png';
import wordfromteam from './assets/wordfromteam.png';
import pastexhi from './assets/PASTEXHI_HEADER.svg';
import theme from './assets/theme.png';
import mmc from './assets/mmc.png';
import events from './assets/events.png';
import competition from './assets/competition.png';
import contactus from './assets/contactus.png';
import hoveryt from './assets/youtube.svg';
// import Footer from "../../components/Footer/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRightLong} from '@fortawesome/free-solid-svg-icons';
import Card from './card';
import CardHeading from './headingCard';
import Pulse1 from './assets/Pulse1.png';
import mainrobot from './assets/mainrobo.png';
import rotatingCircle from './assets/Rotating circle.png';
import innerCircle from './assets/inner circle.png';
import innermostCircle from './assets/innermost circle.png';
import dots from './assets/dots.png';
// import { Filter } from '@material-ui/icons';
import borderBox from './assets/borderBox.png'
import dots1 from './assets/img1_bubbles.png'
import dots2 from './assets/img2_bubbles.png'
import ParticlesComponent from './particles';
// import { gsap } from 'gsap';
import pulsecircle from './assets/pulsecircle.png';
import L1 from './assets/L1.jpg';
import L2 from './assets/L2.png';
import L3 from './assets/L3.jpg';
import L4 from './assets/L4.jpg';
import E1 from './assets/E1.jpg';
import E2 from './assets/E2.jpg';
import E3 from './assets/E3.jpg';
import E4 from './assets/E4.jpg';
import AI from './assets/AI.jpeg';
import ML from './assets/ML.jpeg';
import Techfest_Olympiad  from './assets/Techfest_Olympiad.jpg'
import Cozmoclench  from './assets/Cozmoclench.jpg'
import Meshmerize  from './assets/Meshmerize.jpg'
import CoDecode  from './assets/CoDecode.jpg'
import StockMakert from './assets/StockMarket.jpeg';
import Python from './assets/Python.jpeg';


const Home = () => {
    // useEffect(() => {
    //     const numberOfItems = 13; // You can set this to any number you want
    //     const styleSheet = document.styleSheets[0];

    //     for (let i = 0; i < numberOfItems; i++) {
    //         const angle = i * (360 / numberOfItems);
    //         const rule = `
    //             .${styles.galleryItem}:nth-child(${i + 1}) {
    //                 transform: rotateY(${angle}deg) translateZ(400px);
    //             }
    //         `;
    //         styleSheet.insertRule(rule, styleSheet.cssRules.length);
    //     }
    // }, []);

    const bgHaiJi = {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bg})`, // Dark overlay + background image
        // backgroundColor: "lightblue",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        // backgroundColor:'#e3e3ed',
        width: "100vw",
        position: "absolute",
        top: "0",
        zIndex: "-1",
        backgroundAttachment: "fixed",
        // Filter:'grayscale(400%)',
        
    };

    // const [offsetY,setOffsetY] = useState(0);

    // const handleScroll = () => {
    //   setOffsetY(window.scrollY);

    //   document.querySelector(`.${styles.mainrobot}`).styles.transform = `translateY(${offsetY*3}px)`;
    // }


    // useEffect( () => {
    //   window.addEventListener("scroll",handleScroll);

    //   return () => window.removeEventListener("scroll", handleScroll)
    // },[])

    return (
        <div className={styles.home} style={bgHaiJi}>
            {/* <GrainText/> */}
            {/* <Carousel /> */}
            <div className={styles.content}>
              <div className={styles.firstView}>
                <div className={styles.left}>
                  <ParticlesComponent/>
                  {/* <ParticlesComponent/> */}
                {/* <div className={styles.line}>
              <div className={styles.pulse}></div>
              </div>
              <div className={styles.line1}>
              <div className={styles.pulse}></div>
              </div> */}
              
              


                {/* <img src={Pulse1} alt="pulse" className={styles.Pulse1} /> */}
                {/* <div className={styles.timtim} style={{
                        top: '6.5%',
                        left: '19%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '10.2%',
                        left: '48%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '12.9%',
                        left: '73%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '18.4%',
                        left: '58%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '21%',
                        left: '91.5%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '24.5%',
                        left: '96%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '29.6%',
                        left: '65.5%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '31.7%',
                        left: '50.5%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '33.1%',
                        left: '48%' }}
                ></div>
                <div className={styles.timtim} style={{
                        top: '34.3%',
                        left: '69%' }}
                ></div> */}
                </div>
                <div className={styles.middle}>
                  <div className={styles.bgcircle1}>
                    {/* <div className={styles.rotate}>
                      <img className={styles.rotatingCircle} src={rotatingCircle}></img>
                      <img className={styles.innerCircle} src={innerCircle}></img>
                      <img className={styles.innermostCircle} src={innermostCircle}></img>
                    </div> */}
                  </div>
                  <div className={styles.verticalLine}>
                    <div className={styles.hallowCircle}></div>
                  </div>
                  <div className={styles.horizontalLine}>
                    <div className={styles.hallowCircle1}></div>
                    <div className={styles.hallowCircle2}></div>
                  </div>
                  {/* <div className={styles.horizontalLine2}>
                    <div className={styles.hallowCircle1}></div>
                    <div className={styles.hallowCircle2}></div>
                  </div> */}
                  <div className={styles.bgcircle2}></div>
                  <img src={mainrobot} alt="Robot Image" id={styles.mainrobot} className={styles.mainrobot}></img>
                  
                </div>
                <div className={styles.right}>
                <img src={dots1} alt="pulse" className={styles.dots1} />
                <img src={dots2} alt="pulse" className={styles.dots2} />
                </div>
              </div>
              <div className={styles.gradient}></div>
              <CardHeading image={wordfromteam}/>
              <div className={styles.vid_desc_container}>
                <div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/yPpfN0giVVM?si=3wxKdmFFmNz8h9Rj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" className={styles.video} allowFullScreen></iframe>
                </div>
                <div className={styles.desc}>Welcome to the Official Website of the 28th Edition of Techfest, Asia's Largest Science and Technology Festival! We, the students of IIT Bombay, have been passionately working over the past few months to bring you an unforgettable experience at Techfest 2024-2025. This year's edition is packed with a wide array of events, promising an exhilarating journey that will leave a lasting impression. Your adventure begins right here on this website! Explore all the available avenues to ensure you don't miss a moment of the excitement and innovation that awaits you at Techfest 2024-25</div>
              </div>

              <CardHeading image={theme}/>
              <div className={styles.vid_desc_container}>
                <div >
                <iframe width="560" height="315" src="https://www.youtube.com/embed/tRLNKlpzlNw?si=FJ3C_mlubCJfReIZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" className={styles.video} allowFullScreen></iframe>
                </div>
                <div className={styles.desc}>Step into the 28th Edition of Techfest and dive into the powerful fusion of Artificial Intelligence and Sustainability. Enter a realm where innovative AI technologies lead the way in crafting sustainable solutions, turning visionary ideas into tangible, real-world impact. Discover the forefront of machine learning and explore groundbreaking sustainable innovations that are shaping the future of our planet. Prepare yourself for a Techno-Sustainable extravaganza, and embark on a transformative journey into a future where AI and sustainability unite to push the boundaries of what’s possible!</div>
              </div>
              {/* <div className={styles.payment}>
                <div className={styles.paymentbox}>
              <iframe src="https://konfhub.com/widget/prompt-engineering?desc=false&secondaryBg=F7F7F7&ticketBg=F7F7F7&borderCl=F7F7F7&bg=FFFFFF&fontColor=572148&ticketCl=572148&btnColor=5094fb&fontFamily=Prompt&borderRadius=10" id="konfhub-widget" title="Register for Prompt Engineering" width="100%" height="500">
              </iframe>
              </div>
              </div> */}
              <div className={styles.cardrow}>
                <Card image={events} path={"/exhibitions"} text="Events"/>
                <Card image={competition} path={"/competitions"} text="Competitions"/>
                <Card image={workshop} path={"/workshops"} text="Workshops"/>
                <Card image={contactus} path={"/contactus"} text="Contact Us"/>
              </div>
              <CardHeading image={workhead}/>
              <div className={styles.cardrow}>
                <Card image={AI} path={"/workshops"} text="Artificial Intelligence" description="Artificial Intelligance"/>
                <Card image={ML} path={"/workshops"} text="Machine Learning" description="Machine Learning"/>
                <Card image={StockMakert} path={"/workshops"} text="Stock Market" description="Stock Market"/>
                <Card image={Python} path={"/workshops"} text="Python" description="Python"/>
              </div>
              <a href='/workshops' className={styles.exploreAnchor}>
  <div className={styles.explorebtn}>
    EXPLORE
    <FontAwesomeIcon icon={faArrowRightLong} className={styles.exploreIcon} />
  </div>
</a>
              {/* <CardHeading image={titlehead}/> */}

              {/* <CardHeading image={mmc}/> */}
              {/* <div className={styles.mmc}><img src={mmc} style={{padding:'8px',marginBottom:'50px'}}></img></div> */}
              
              
            <div class="line-container">
                <div class="line"></div>
                <div class="line turn"></div>
                <div class="line"></div>
            </div>

              <CardHeading image={compihead}/>
              <div className={styles.cardrow}>
                <Card image={Cozmoclench} path={"/competitions"} text="Cozmoclench"/>
                <Card image={Meshmerize} path={"/competitions"} text="Meshmerize"/>
                <Card image={CoDecode} path={"/competitions"} text="CoDecode"/>
                <Card image={Techfest_Olympiad} path={"/competitions"} text="Techfest Olympiad"/>
              </div>
              <a href='/competitions' className={styles.exploreAnchor}>
  <div className={styles.explorebtn}>
    EXPLORE
    <FontAwesomeIcon icon={faArrowRightLong} className={styles.exploreIcon} />
  </div>
</a>
              

              <CardHeading image={pastlectures}/>
              <div className={styles.cardrow}>
                <Card image={L1} path={"/lectures"} text="Shri. Akash Ambani" description="Chairman of Reliance Jio Infocomm Limited"/>
                <Card image={L2} path={"/lectures"} text="Shri. S. Somanath" description="Chairman of ISRO"/>
                <Card image={L3} path={"/lectures"} text="Dr. A.P.J. Abdul Kalam" description="Former President of India, also know as Missile man of India"/>
                <Card image={L4} path={"/lectures"} text="The 14th Dalai Lama" description="His holiness 14th Dalai lama, Nobel Peace Prize Winner"/>
              </div>
              <a href='/lectures' className={styles.exploreAnchor}>
  <div className={styles.explorebtn}>
    EXPLORE
    <FontAwesomeIcon icon={faArrowRightLong} className={styles.exploreIcon} />
  </div>
</a>

              <CardHeading image={pastexhi}/>
              <div className={styles.cardrow}>
                <Card image={E1} path={"/exhibitions"} text="SOPHIA" description="World's first humanoid robot to get citizenship"/>
                <Card image={E2} path={"/exhibitions"} text="Einstein Robot" description="Humanoid robot that emulates Albert Einstein"/>
                <Card image={E3} path={"/exhibitions"} text="Gravity Industries" description="The 𝗗𝗮𝗲𝗱𝗮𝗹𝘂𝘀, an exoskeleton that enables humans to fly!"/>
                <Card image={E4} path={"/exhibitions"} text="Proto Hologram" description="Compact tabletop holographic communicator"/>
              </div>
              <a href='/exhibitions' className={styles.exploreAnchor}>
  <div className={styles.explorebtn}>
    EXPLORE
    <FontAwesomeIcon icon={faArrowRightLong} className={styles.exploreIcon} />
  </div>
</a>
                                 {/* <Footer/> */}
                <div className={styles.footer}>
                    <p className={styles.copyright}>© Techfest IIT Bombay</p>
                    {/* <p className={styles.icons}>
                    <div className={styles.iconcontainer}>
                        <a href="https://www.instagram.com/techfest_iitbombay/">
                      <img src={instagram} alt="" className={styles.backgroundImage}/>
                      <img src={hoverinsta} alt="" className={styles.hoverImage}/>
                      </a>
                  </div>
                  <div className={styles.iconcontainer}>
                      <a href="https://www.facebook.com/iitbombaytechfest/">
                      <img src={facebook} alt="" className={styles.backgroundImage}/>
                      <img src={hoverfb} alt="" className={styles.hoverImage}/>
                      </a>
                  </div>
                  <div className={styles.iconcontainer}>
                      <a href="https://www.youtube.com/user/techfestiitbombay">
                      <img src={youtube} alt="" className={styles.backgroundImage}/>
                      <img src={hoveryt} alt="" className={styles.hoverImage}/>
                      </a>
                      </div>
                  <div className={styles.iconcontainer}>
                      <a href="https://x.com/Techfest_IITB?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                      <img src={twitter} alt="" className={styles.backgroundImage}/>
                      <img src={hovertw} alt="" className={styles.hoverImage}/>
                      </a>
                  </div>

                  <div className={styles.iconcontainer}>
                      <a href="https://in.pinterest.com/techfestiitbombay/">
                      <img src={Pinterest} alt="" className={styles.backgroundImage}/>
                      <img src={hoverpin} alt="" className={styles.hoverImage}/>
                      </a>
                      </div>

                  <div className={styles.iconcontainer}>
                      <a href="https://in.linkedin.com/company/techfest">
                      <img src={linkedin} alt="" className={styles.backgroundImage}/>
                      <img src={hoverlink} alt="" className={styles.hoverImage}/>
                      </a>
                  </div>
                    </p> */}
                </div>
                  {/* <div className={styles.gallery}>
                      {[...Array(13)].map((_, i) => (
                          <div key={i} className={styles.galleryItem}></div>
                      ))}
                  </div> */}
            </div>
        </div>    

    );
}
export default Home;
