import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { UserAuth } from '../../context/AuthContext';
import styles from './profile.module.css';
import bg from '../Home/assets/profile_bg2.jpg';

const Profile = () => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  const { user } = UserAuth();

  const [profileData, setProfileData] = useState({ competitions: [], workshops: [] });
  const [isLoading, setIsLoading] = useState(true);

  const email = user?.email;

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const [competitionsResponse, workshopsResponse] = await Promise.all([
          axios.get("https://techfest.org/api/profile_compi/", {
            headers: {
              "X-Google-UID": user?.uid,
            },
          }),
          axios.get("https://techfest.org/api/profile_workshop/", {
            headers: {
              "Email": email,
            },
          }),
        ]);

        if (competitionsResponse.status === 200 && workshopsResponse.status === 200) {
          setProfileData({
            competitions: competitionsResponse.data.competitions,
            workshops: workshopsResponse.data.workshops,
          });
        } else {
          console.error("Error:", competitionsResponse.status, workshopsResponse.status);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (email) {
      fetchProfileData();
    }
  }, [email, user?.uid]);

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  const bgHaiJi = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor:'white',
    backgroundPosition: "center center",
    width: "100vw",
    position: "absolute",
    top: "0",
    zIndex: "-1",
    backgroundAttachment: "fixed",
  };

  return (
    <div>
    <div className={styles.profile} style={bgHaiJi}>
      <div className={styles.profilearrange}>
      <div className={styles.profileHeader}>
        <div className={styles.profilePhoto}>
          {user?.photoURL ? (
            <img src={user.photoURL} alt="Profile" />
          ) : (
            <p>No Profile Image</p>
          )}
        </div>
        <div className={styles.profileInfo}>
          <h2 style={{color:'white',fontFamily:'Bruno Ace'}}>{user?.displayName}</h2>
          <p style={{color:"white",fontFamily:'Bruno Ace'}}>{user?.email}</p>
        </div>
      </div>
      <div className={styles.profileContent}>
        <h3>Registrations</h3>
        <div className={styles.compiwork}>
          <div className={styles.compi}>
        <h4>Competitions:</h4>
        <div className={styles.registrations}>
          {profileData.competitions.length > 0 ? (
            profileData.competitions.map((competition) => (
              <div key={competition.id} className={styles.competition}>
                <h5>{competition.name}</h5>
                {/* <p>Max Team Length: {competition.max_team_length}</p> */}
                <p>Prize: {competition.prize}</p>
                {competition.img && <img src={competition.img} alt={competition.name} />}
                {competition.statement && (
                  <a href={competition.statement} target="_blank" rel="noopener noreferrer">
                    Statement
                  </a>
                )}
                {competition.sponsorImg && <img src={competition.sponsorImg} alt="Sponsor" />}
              </div>
            ))
          ) : (
            <p>No competitions registered.</p>
          )}
        </div>
        </div>
        <div className={styles.work}>
        <h4>Workshops:</h4>
        <div className={styles.registrations}>
          {profileData.workshops.length > 0 ? (
            profileData.workshops.map((workshop) => (
              <div key={workshop.id} className={styles.workshop}>
                <h5>{workshop.name}</h5>
                {/* <p>Description: {workshop.desc}</p> */}
                <p>Prize: {workshop.prize}</p>
                {/* <p>Extended Prize: {workshop.ex_prize}</p> */}
                {workshop.img && <img src={workshop.img} alt={workshop.name} />}
                {workshop.statement && (
                  <a href={workshop.statement} target="_blank" rel="noopener noreferrer">
                    Statement
                  </a>
                )}
                {/* {workshop.sponsorImg && <img src={workshop.sponsorImg} alt="Sponsor" />} */}
                {/* <p>Payment Link: {workshop.paymentLink}</p> */}
                {/* <p>Closed: {workshop.closed ? "Yes" : "No"}</p> */}
              </div>
            ))
          ) : (
            <p>No workshops registered.</p>
          )}
        </div>
        </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Profile;
