import React from 'react'
import styles from './home.module.css'

const HeadingCard = ({image}) => {
  return (
    <div className={styles.workshophead}>
        <img src={image} alt='img not found'></img>
    </div>
  )
}

export default HeadingCard
