import React, { useEffect, useState, useRef } from 'react';
import mylogo from '../Lectures/assets/grouped_circle.png';
import '../Lectures/ourreach.css';
import downrot from '../Lectures/assets/downrot.png';
import upprot from '../Lectures/assets/upprot.png';
import ourreach from '../Lectures/assets/ourreach.png';

function OurReach() {
  // Counter Component defined within OurReach
  const Counter = ({ targetValue, duration, plus = '' }) => {
    const [count, setCount] = useState(0);
    const [hasStarted, setHasStarted] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !hasStarted) {
            setHasStarted(true);
            observer.disconnect(); // Disconnect observer to prevent multiple triggers
          }
        },
        { threshold: 0.5 }
      );

      if (counterRef.current) {
        observer.observe(counterRef.current);
      }

      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }, [hasStarted]);

    useEffect(() => {
      if (!hasStarted) return;

      let start = 0;
      const end = targetValue;
      const increment = end / (duration / 10);
      const timer = setInterval(() => {
        start += increment;
        if (start >= end) {
          start = end;
          clearInterval(timer);
        }
        setCount(Math.floor(start));
      }, 10);

      return () => clearInterval(timer);
    }, [targetValue, duration, hasStarted]);

    return (
      <p ref={counterRef}>
        {count}
        {plus}
      </p>
    );
  };

  return (
    <>
      <div className='ourReach'>
        <img src={ourreach} alt="Our Reach" />
      </div>
      <div className='loader'>
        <div className="buffer">
          <img src={mylogo} alt="buffering" id='myImg' />
          <img src={downrot} alt="downrot" id="down" />
          <img src={upprot} alt="upprot" id="up" />
          <div className='Overlay'>
            <Counter targetValue={350} duration={2000} plus='+' />
          </div>
          <div className='bottomhead'>
            <h2 style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
              International Exhibits
            </h2>
          </div>
        </div>
        <div className="buffer">
          <img src={mylogo} alt="buffering" id='myImg' />
          <img src={downrot} alt="downrot" id="down" />
          <img src={upprot} alt="upprot" id="up" />
          <div className='Overlay'>
            <Counter targetValue={500} duration={2000} plus='+' />
          </div>
          <div className='bottomhead'>
            <h2 style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
              International Universities
            </h2>
          </div>
        </div>
        <div className="buffer">
          <img src={mylogo} alt="buffering" id='myImg' />
          <img src={downrot} alt="downrot" id="down" />
          <img src={upprot} alt="upprot" id="up" />
          <div className='Overlay'>
            <Counter targetValue={60} duration={2000} plus='K+' />
          </div>
          <div className='bottomhead'>
            <h2>Footfall</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurReach;
