import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmModal from './ConfirmModal';
import styles from './Navbar.module.css';
import navbod from './assets/navbod1.png';
import tf from './assets/tflogo2 1.png';
import bg from './navImg3.svg';
import './Header.css';

const Navbar = () => {
  const { user, logOut, googleSignIn } = UserAuth();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSignOut = async () => {
    try {
      await logOut();
      toast.success('Successfully signed out!');
      navigate('/');
    } catch (error) {
      console.log(error);
      toast.error('Failed to sign out. Please try again.');
    }
  };

  const handleSignOutClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmSignOut = () => {
    handleSignOut();
    setIsModalOpen(false);
  };

  const handleCancelSignOut = () => {
    setIsModalOpen(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      navigate('/competitions');
    } catch (error) {
      console.log(error);
      toast.error('Failed to sign in. Please try again.');
    }
  };

  const handleProfileClick = async (e) => {
    e.preventDefault();
    if (!user) {
      try {
        await googleSignIn();
        navigate('/profile'); // Navigate to the profile after signing in
      } catch (error) {
        console.log(error);
        toast.error('Failed to sign in. Please try again.');
      }
    } else {
      navigate('/profile'); // If already signed in, go directly to the profile
    }
  };

  const username = user?.displayName;
  let name = '';
  if (username != null) {
    name = username.split(' ')[0];
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [showNavbar, setShowNavbar] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > prevScrollY) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }

    setPrevScrollY(currentScrollY);
  };

  const [showEvents, setShowEvents] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const showMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div>
      <nav
        className={`navfunc webNav ${
          showNavbar ? 'navfuncshow' : 'navfunchide'
        }`}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <ConfirmModal
          isOpen={isModalOpen}
          onConfirm={handleConfirmSignOut}
          onCancel={handleCancelSignOut}
        />
        <div
          className="wnavLeft navRow"
          onMouseLeave={() => setShowEvents(false)}
        >
          <li>
            <Link to="/accomodation" onMouseEnter={() => setShowEvents(false)}>
              Accomodation
            </Link>
          </li>

          <li>
            <Link onMouseEnter={() => setShowEvents(true)}>Events</Link>
            <div
              className={`tuJhoom ${showEvents ? 'evenLoShow' : 'evenLoNo'}`}
              onMouseEnter={() => setShowEvents(true)}
              onMouseLeave={() => setShowEvents(false)}
            >
              <ul>
                
                <li>
                  <Link to="/exhibitions">Exhibitions</Link>
                </li>
                <li>
                  <Link to="/lectures">Lectures</Link>
                </li>
                <li>
                  <Link to="/mun">MUN</Link>
                </li>
                <li>
                  <Link to="/summits">Int'l Summit</Link>
                </li>
                <li>
                  <Link to="/ift">Full Throttle</Link>
                </li>
                <li>
                  <Link to="/robowars">Robowars</Link>
                </li>
                

              </ul>
            </div>
          </li>
          <li>
            <Link to="/workshops" onMouseEnter={() => setShowEvents(false)}>
              Workshops
            </Link>
          </li>
        </div>
        <div className="logoDiv navRow">
          <li>
            <Link to="/">
              <img src={tf} alt="Techfest" className="tflogoka" />
            </Link>
          </li>
        </div>
        <div className="wnavRight navRow">
          <li>
            <Link to="/competitions">Competitions</Link>
          </li>
          {/* Profile link styled as a regular navbar link */}
          <li>
            <Link
              to="/profile"
              className="navContact"
              onClick={handleProfileClick}
            >
              Profile
            </Link>
          </li>
          <li>
            <div className={styles['auth-buttons']}>
              {user?.displayName ? (
                <button
                  onClick={handleSignOutClick}
                  className={styles['sign-out-button']}
                  onMouseEnter={(e) => (e.target.innerText = 'Sign Out')}
                  onMouseLeave={(e) => (e.target.innerText = name)}
                >
                  {name}
                </button>
              ) : (
                <button
                  onClick={handleGoogleSignIn}
                  className={styles['sign-in-button']}
                >
                  Sign In
                </button>
              )}
            </div>
          </li>
        </div>
      </nav>
      <div className={styles.hamburger} onClick={toggleSidebar}>
        ☰
      </div>
      <div className={styles.tflogohead}>
        <img src={tf} alt="" />
      </div>
      <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`}>
        <div className={styles.link}>
          <a href="/competitions">Competitions</a>
        </div>
        <div className={styles.link}>
          <a href="/workshops">Workshops</a>
        </div>
        <div className={styles.link}>
          <a href="/profile" onClick={handleProfileClick}>
            Profile
          </a>
        </div>
        <div className={styles.link}>
          <a href="/">Home</a>
        </div>
        <div className={styles.link}>
          <a href="/exhibitions">Exhibitions</a>
        </div>
        <div className={styles.link}>
          <a href="/lectures">Lectures</a>
        </div>
        <div className={styles.link}>
          <a href="/contactus">Contact Us</a>
        </div>
        <div className={styles.link}>
          <a href="/aboutus/history">History</a>
        </div>
        <div className={styles.link}>
          <a href="/aboutus/legals">Legals</a>
        </div>
        <div className={styles.link}>
          <a href="/aboutus/recognition">Recognition</a>
        </div>
        <div className={styles.link}>
          <a href="/aboutus/media">Media</a>
        </div>
        <ConfirmModal
          isOpen={isModalOpen}
          onConfirm={handleConfirmSignOut}
          onCancel={handleCancelSignOut}
        />
        <div className={styles['auth-buttons']}>
          {user?.displayName ? (
            <button
              onClick={handleSignOutClick}
              className={styles['sign-out-button']}
              onMouseEnter={(e) => (e.target.innerText = 'Sign Out')}
              onMouseLeave={(e) => (e.target.innerText = name)}
            >
              {name}
            </button>
          ) : (
            <button
              onClick={handleGoogleSignIn}
              className={styles['sign-in-button']}
            >
              Sign In
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
